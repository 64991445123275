<template lang="pug">
  // форма запроса обратного звонка
  .platform-callback-form.section: validation-observer(ref="observer" v-slot="{valid, invalid, handleSubmit}")
    .form-header(ref="header" v-if="isNotSuccess")
      p.title.is-4(ref="title") Обратный звонок
    .form-messages(ref="messages")
      // отображение сообщения основано на комбинации/изменении статусов
      // (также можно использовать b-message вместо b-notification)
      b-notification(type="is-danger" @close="onCloseNotification" has-icon v-model="status.error")
        p: strong Ошибка! Не удалось отправить сообщение.
        p Попробуйте снова, если ошибка повторяется, обновите страницу.
        p Описание ошибки:
        template(v-for="error in errors")
          strong {{error}}
      b-notification(type="is-success" @close="onCloseNotification" has-icon v-model="status.success")
        p: strong Спасибо! Ваше сообщение отправлено.
        p Постараемся рассмотреть ваше обращение как можно быстрее.
    .form-wrapper(v-if="isNotSuccess" ref="wrapper")
      form.form(@submit.prevent.stop="handleSubmit(submit)" ref="form" enctype="multipart/form-data")
        // на разных сайтах для одних и тех же форм
        // идентификатор может быть разным, это связано с
        // правилами обновления и синхронизации данных
        // --
        // обязательный атрибут, по этому атрибуту
        // битрикс понимает какая форма отправлена со страницы
        input(type="hidden" name="WEB_FORM_ID" :value="formId")
        // @deprecated пользователь не может отправлять данные без согласия на обработку
        input(type="hidden" name="form_text_36" value="да")



        .columns
          .column: validation-provider(v-slot="v" rules="required")
            b-field(:type="{'is-danger': v.invalid && v.dirty, 'is-success': v.valid }")
              b-input(name="form_text_33" :disabled="isLock" v-model="formData.name" placeholder="Имя и фамилия")
          .column: validation-provider(v-slot="v" rules="required")
            b-field(:type="{'is-danger': v.invalid && v.dirty, 'is-success': v.valid }")
              b-input(name="form_text_34" :disabled="isLock" v-model="formData.phone" placeholder="Телефон")
          .column.is-narrow
            b-field
              button.button.is-primary(type="submit" :disabled="isLock || invalid"
                :class="{ 'is-loading': isLock }") отправить
        p Заполните и отправьте свои контактные данные, <strong>мы Вам перезвоним</strong>.
        p.help.
          Отправляя форму вы соглашаетесь на обработку ваших персональных данных
          в соответствие с <a href="/agreement/" target="_blank">пользовательским соглашением сайта</a>. Передаваемая контактная информация используется исключительно для связи с Вами.
</template>
<script>
import FormCommonMixin from '@/platform/mixins/form-common-mixin'

/**
 * @deprecated use mixins
 */
export default {
  mixins: [FormCommonMixin],
  data () {
    return {
      formId: 3
    }
  }
}
</script>
<style lang="sass" scoped>
@import "bulma-theme"
.platform-callback-form.section
  padding: 1.4rem
  +mobile
    background: red
.platform-callback-form
  background: #fff
  border-radius: 6px
p.title
  margin-bottom: 1rem
.is-success
  .title
    margin-bottom: 0
  p.success-message
    margin-bottom: 1rem
</style>

<template lang="pug">
  a.review-question-trigger.button(@click.stop.prevent="openForm" :class="customClass")
    slot(name="title")
      span.button-title {{title}}
    slot(name="icon" v-if="isNotHiddenIcon")
      span.icon: i.fas.fa-comment-dots
</template>
<script>
import {ReviewQuestionForm} from "@/platform/components/reviews";
import FormTriggerMixin from "@/platform/mixins/form-trigger-mixin";

export default {
  mixins: [FormTriggerMixin],
  methods: {
    openForm() {
      this.$buefy.modal.open({
        parent: this,
        component: ReviewQuestionForm,
        customClass: 'form-in-modal',
        canCancel: ['x'],
        width: 760,
        props: {
          /**
           * опция используется для определения
           * что форма открыта в модальном окне,
           * это не стандартная опция она добавлена
           * специально в форму, и отслеживается
           * при закрытии уведомления об успешной
           * отправке
           */
          trigger: true
        }
      })
    }
  }
}
</script>
<style lang="sass">
a.review-question-trigger.button
  .button-title
    margin-right: .5rem
</style>

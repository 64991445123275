import Swiper from 'swiper'

// require('swiper/swiper.scss')
// require('swiper/css/swiper.css')
require('./style.scss')

function start() {
  /**
   * @see https://swiperjs.com/api/
   */
  return new Swiper('.js-top-slider', {
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
    loop: true,
    speed: 400,
    paginationClickable: true,
    pagination: {
      el: '.js-swiper-pagination-top',
      clickable: true
    }
  })
}

export default {
  start
}

<template lang="pug">
  .platform-feedback-form: validation-observer(v-slot="{valid, invalid, handleSubmit}" ref="observer")
    .form-header(ref="header" v-if="isNotSuccess")
      p.title.is-4(ref="title") Обратная связь
    .form-messages(ref="messages")
      // отображение сообщения основано на комбинации/изменении статусов
      // (также можно использовать b-message вместо b-notification)
      b-notification(type="is-danger" @close="onCloseNotification" has-icon v-model="status.error")
        p Ошибка! Не удалось отправить сообщение.
        p Попробуйте снова, если ошибка повторяется, обновите страницу.
        // можно убрать подробный вывод ошибок
        p Описание ошибки:
        template(v-for="error in errors")
          strong {{error}}
      b-notification(type="is-success" @close="onCloseNotification" has-icon v-model="status.success")
        p Спасибо! Ваше сообщение отправлено.
        p Постараемся ответить как можно быстрее.
    .form-wrapper(v-if="isNotSuccess")
      form.form(@submit.prevent.stop="handleSubmit(submit)" ref="form" enctype="multipart/form-data")
        // обязательный атрибут, по этому атрибуту
        // битрикс понимает какая форма отправлена со страницы
        input(type="hidden" name="WEB_FORM_ID" :value="formId")
        // имена полей уникальны в одной установке Битрикс
        // Битрикс полчает список полей по ID формы и затем
        // выбирает поля конкретной формы из объекта $_REQUEST
        // Ф.И.О
        validation-provider(v-slot="v" rules="required" name="Контакты" slim)
          b-field(label="Контакты *" message="Ваши контакты, Ф.И.О" :type="{ 'is-danger': v.invalid && v.dirty }")
            b-input(name="form_text_41" placeholder="Имя и Фамилия" v-model="formData.name")
          p.has-text-danger {{v.errors[0]}}
        // Телефон
        validation-provider(v-slot="v" rules="required" name="Телефон" slim)
          b-field(label="Телефон *" message="Ваш контактный телефон")
            b-input(name="form_text_38" placeholder="+7 000 000-00-00" v-model="formData.phone")
          p.has-text-danger {{v.errors[0]}}
        // Email
        validation-provider(v-slot="v" rules="required|email" name="Email" slim)
          b-field(label="Email *" message="Ваш адрес электронной почты" :type="{ 'is-danger': v.invalid && v.dirty }")
            b-input(name="form_email_2" placeholder="email@email.ru" v-model="formData.email")
          p.has-text-danger {{v.errors[0]}}
        // Сообщение
        validation-provider(v-slot="v" rules="required|max:1000" slim name="Сообщение")
          b-field(label="Сообщение *" message="Сообщение или ваши вопросы" type="{'is-danger': v.invalid}")
            b-input(name="form_textarea_1" placeholder="сообщение или вопрос..." type="textarea"
              v-model="formData.message")
          p.has-text-danger {{v.errors[0]}}
        // Файл
        b-field.file.is-primary(:class="{'has-name': !!formData.file}")
          b-upload.file-label(v-model="formData.file" name="form_file_51")
            span.file-cta
              b-icon.file-icon(icon="upload")
              span.file-label прикрепить файл
            span.file-name(v-if="formData.file") {{ formData.file.name }}
        b-message(style="margin-top: 2rem;"): p.help.
          Отправляя сообщение вы соглашаетесь <a target="_blank" href="/agreement">с условиями</a>. Если вы не
          согласны <a target="_blank" href="/agreement">с условиями
          пользовательского соглашения</a>, не отправляйте форму.
          Ваши данные будут использованы только для связи с вами, для ответа на ваше сообщение.

        .controls: .level.is-mobile
          .level-left
            .level-item: b-button(native-type="reset") очистить форму
          .level-right
            .level-item: b-button(type="is-primary"
              native-type="submit"
              @click.prevent="submit"
              :disabled="invalid"
              :class="{'is-loading': isLock}") отправить
</template>
<script>
import FormCommonMixin from '@/platform/mixins/form-common-mixin'

export default {
  mixins: [FormCommonMixin],
  data() {
    return {
      formId: 1,
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'bulma-theme'
// common
.platform-feedback-form
  background: #fff
.form-header
  margin-bottom: 1rem
  padding-bottom: 1rem
  border-bottom: 1px solid #eee
  background: #fff
// in modal
.form-in-modal .platform-feedback-form
  .form-wrapper
    background: #fff
    padding: 2rem
  .form-header
    background: $primary
    p.title
      padding-left: 2rem
      padding-top: 1rem
      color: $primary-invert
</style>

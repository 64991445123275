import Swiper, { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper'

/**
 * начиная с версии 5.x различные модули необходимо
 * импортировать и подключать отдельно, это касается и
 * javascript и стилей, это рекомендованный подход
 */
Swiper.use([Navigation, Pagination, Scrollbar, Autoplay])

/**
 * стили можно импортировать сразу
 * или использовать отдельный файл стилей
 * для возможности переопределения "родных"
 * стилей и переменных Swiper
 */

require('./style.scss')

function start() {
  /**
   *
   * @see demo /demo/pswp/
   * @see api https://swiperjs.com/api/
   */
  return new Swiper('.swiper-pswp-demo', {
    watchSlidesProgress: true,
    slidesPerView: 1,
    loop: true,
    breakpoints: {},
    autoplay: {
      delay: 3000
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets'
    },
    scrollbar: {
      el: '.swiper-scrollbar'
    }
  })
}

export default {
  start
}

import GalleryExample from './galleries/gallery-example'
import GallerySwiperExample from './galleries/gallery-swiper-example'
import GalleryLinks from './galleries/gallery-links'

/**
 * при использовании runtime-логики
 * шаблон .pswp следует подключить
 * в footer.php шаблона сайта
 *
 * Каждая отдельная инициализация галереи
 * размещается в отдельном файле
 */

function start(){
  GalleryExample.init('.gallery-example')
  GallerySwiperExample.init('.gallery-swiper-example')

  /**
   * чтобы избежать ошибок
   * можно проверять наличие селекторов
   * на странице до запуска библиотеки
   */
  if (document.querySelectorAll('a[rel=gallery]').length > 0) {
    GalleryLinks.init('a[rel=gallery]')
  }
}

export default {
  start
}

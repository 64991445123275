import FeedbackForm from "@/platform/components/feedback/FeedbackForm"
import FeedbackFormTrigger from "@/platform/components/feedback/FeedbackFormTrigger"
import { use, registerComponent } from "@/utils/plugins"

/**
 * При глобальной регистрации компонента
 * будет доступен "глобально" на уровне Vue,
 * то есть использовать <feedback-form></feedback-form>
 * в любых Vue компонентах.
 */
const Plugin = {
  install(Vue) {
    registerComponent(Vue, FeedbackForm)
  }
}

/**
 * use проверяет window.Vue и
 * регистрирует компонент
 */
use(Plugin)

export default Plugin

/**
 * Так же экспортируем компонент
 */
export {
  FeedbackForm,
  FeedbackFormTrigger
}

import Vue from 'vue'
import VueResource from 'vue-resource'

function init() {
  Vue.use(VueResource)

  /**
   * Пример заголовков авторизации
   *
   * эти данные должны использоваться только на сервере
   * @type {string}
   */
  // Vue.http.options.root = 'http://demo1.autocrm.ru/yii/api'
  // Vue.http.headers.common['Authorization'] = 'Basic cm9wOmNybV9kZW1vMQ=='
}

export default {
  init
}

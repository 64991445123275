import FeedbackIndexForm from "@/components/feedback-index/FeedbackIndexForm"
import { use, registerComponent } from "@/utils/plugins"

require('./style.scss')

/**
 * При глобальной регистрации компонента
 * будет доступен "глобально" на уровне Vue,
 * то есть использовать <feedback-index-form></feedback-index-form>
 * в любых Vue компонентах.
 */
const Plugin = {
  install(Vue) {
    registerComponent(Vue, FeedbackIndexForm)
  }
}

/**
 * use проверяет window.Vue и
 * регистрирует компонент
 */
use(Plugin)

export default Plugin

/**
 * Так же экспортируем компонент
 */
export {
  FeedbackIndexForm
}

import Vue from 'vue'
import {ReviewQuestionForm} from "@/platform/components/reviews/forms/review-question-form";
import {ReviewQuestionTrigger} from '@/platform/components/reviews/triggers/review-question-trigger'

/**
 * инициализация триггеров
 * и помощников
 */
function start() {
  /**
   * кнопки "задать вопрос" (вопрос-ответ)
   *
   * ```html
   * <span class="js-review-question-trigger">
   *   <review-question-trigger title="оставить отзыв" custom-class="is-danger" />
   * </span>
   *
   *
   *
   */
  const buttons = document.querySelectorAll('.js-review-question-trigger')
  buttons.forEach(el => {

    /**
     * отрисовка компонента напрямую
     * не дает возможности использовать слоты
     * и параметры, кнопка будет создана "как есть"
     */
    // new Vue({
    //   render: (h) => h(ReviewQuestionTrigger)
    // }).$mount(el)

    new Vue({
      components: {
        ReviewQuestionTrigger
      }
    }).$mount(el)
  })

  /**
   * монтирование (отображение) формы на странице
   * подразумевает размещение только одной формы
   * на странице.
   *
   * Разместите элемент на странице, обычно в
   * шаблоне компонента. Использование битрикс компонентов -
   * рекомендуемый способ подключения к странице.
   *
   * ```html
   * <div id="js-review-question-form">
   *   загружаю форму...
   * </div>
   * ```
   *
   */
  const reviewQuestionFormDOM = document.getElementById('js-review-question-form')
  if (reviewQuestionFormDOM) {
    new Vue({
      render: h => h(ReviewQuestionForm)
    }).$mount(reviewQuestionFormDOM)
  }
}

export default {
  start
}
/**
 * экспорт всех элементов
 * для возможности использования отдельно
 * от общего модуля
 */
export {
  ReviewQuestionForm,
  ReviewQuestionTrigger,
}

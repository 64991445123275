import ReviewQuestionTrigger from "./ReviewQuestionTrigger";
import {use, registerComponent} from '@/utils/plugins'

const Plugin = {
  install(Vue) {
    registerComponent(Vue, ReviewQuestionTrigger)
  }
}

use(Plugin)

export default Plugin

export {
  ReviewQuestionTrigger
}

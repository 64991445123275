/**
 * Триггеры форм - это элементы при взаимодействии
 * с которыми открывается модальное окно с формой внутри.
 *
 * Обычно это формы обратной связи, обратный звонок и т.д.
 *
 * На странице размещается элемент триггер и инициализируется
 * как Vue-компонент. Форма
 *
 */
const FormTriggerMixin = {
  props: {
    /**
     * Название кнопки
     */
    title: {
      type: String,
      default: 'обратная связь'
    },
    /**
     * добавить класс к кнопке
     */
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    hideIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isNotHiddenIcon() {
      return this.hideIcon === false
    }
  }
}

export default FormTriggerMixin

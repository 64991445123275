import Swiper from 'swiper'

require('swiper/swiper.scss')
require('./style.sass')

function start() {
  /**
   * @see https://swiperjs.com/api/
   */
  return new Swiper('.swiper-index-services', {
    spaceBetween: 20,
    slidesPerView: 4,
    watchSlidesProgress: true,
    autoplay: {
      delay: 3000
    },
    pagination: {
      el: '.swiper-pagination',
    },
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 4
      }
    }
  })
}

export default {
  start
}

import ReviewQuestionForm from "./ReviewQuestionForm";
import {use, registerComponent} from '@/utils/plugins'

const Plugin = {
  install(Vue) {
    registerComponent(Vue, ReviewQuestionForm)
  }
}

use(Plugin)

export default Plugin

export {
  ReviewQuestionForm
}

/**
 * установка фонового изображения для элемента
 * из атрибута `data-background`.
 *
 * фоновое изображение (background-image)
 * лучше работает для адаптивных блоков
 *
 * конечно дополнительно необходимо
 * добавить css-стили для определения поведения.
 *
 * ```html
 *  <div
 *    class="has-background-image"
 *    data-background="/assets/images/bg.png">
 *    ...
 *  </div>
 * ```
 *
 * рекомендуемый подход
 *
 * <figure class="image has-background-image"
 *  itemscope itemtype="http://schema.org/ImageObject"
 *  data-type="/assets/images/image.jpg">
 *   <a itemprop="contentUrl" href="/assets/images/image.jpg"></a>
 *   <figcaption itemprop="caption description">
 *    Image
 *   </figcaption>
 * </figure>
 *
 */
function init() {
  const images = document.querySelectorAll('.has-background-image')
  images.forEach(el => {
    let imageSrc = el.dataset.background
    if (typeof imageSrc !== 'undefined') {
      el.style.backgroundImage = `url(${imageSrc})`
    }
  })
}

export default {
  start: function(){
    init()
  }
}

<template lang="pug">
  section.section.has-background-primary.has-text-white: .container.py-4
    .platform-feedback-index-form: validation-observer(v-slot="{valid, invalid, handleSubmit}" ref="observer")
      .form-header(ref="header" v-if="isNotSuccess")
        h2.title.has-text-centered.mb-2.has-text-white(ref="title") Рассчитать стоимость обслуживания
        p.has-text-centered.mb-6 Наши специалисты обладают высокой квалификацией и большим опытом в сфере бухгалтерского учета и финансов.
      .form-messages(ref="messages")
        // отображение сообщения основано на комбинации/изменении статусов
        // (также можно использовать b-message вместо b-notification)
        b-notification(type="is-danger" @close="onCloseNotification" has-icon v-model="status.error")
          p Ошибка! Не удалось отправить сообщение.
          p Попробуйте снова, если ошибка повторяется, обновите страницу.
          // можно убрать подробный вывод ошибок
          p Описание ошибки:
          template(v-for="error in errors")
            strong {{error}}
        b-notification(type="is-success" @close="onCloseNotification" has-icon v-model="status.success")
          p Спасибо! Ваше сообщение отправлено.
          p Постараемся ответить как можно быстрее.
      .form-wrapper(v-if="isNotSuccess")
        form.form(@submit.prevent.stop="handleSubmit(submit)" ref="form" enctype="multipart/form-data")
          // обязательный атрибут, по этому атрибуту
          // битрикс понимает какая форма отправлена со страницы
          input(type="hidden" name="WEB_FORM_ID" :value="formId")
          // имена полей уникальны в одной установке Битрикс
          // Битрикс полчает список полей по ID формы и затем
          // выбирает поля конкретной формы из объекта $_REQUEST
          .columns.is-multiline
            .column.is-4
              // Ф.И.О
              validation-provider(v-slot="v" rules="required" name="Имя и Фамилия" slim)
                b-field
                  b-input(name="form_text_55" placeholder="Имя и Фамилия" v-model="formData.name")
                p.has-text-danger {{v.errors[0]}}
            .column.is-4  
              // Телефон
              validation-provider(v-slot="v" rules="required" name="Телефон" slim)
                b-field
                  b-input(name="form_text_54" placeholder="+7 000 000-00-00" v-model="formData.phone")
                p.has-text-danger {{v.errors[0]}}
            .column.is-4  
              // Email
              validation-provider(v-slot="v" rules="required|email" name="Email" slim)
                b-field
                  b-input(name="form_email_53" placeholder="email@email.ru" v-model="formData.email")
                p.has-text-danger {{v.errors[0]}}
            .column.is-6    
              //Организационно-правовая форма
              validation-provider(v-slot="v" rules="required" name="Организационно-правовая форма" slim)
                b-field
                  b-input(name="form_text_57" placeholder="Организационно-правовая форма" v-model="formData.form")
                p.has-text-danger {{v.errors[0]}}
              // Количество сотрудников в компании
              validation-provider(v-slot="v" rules="required" name="Количество сотрудников в компании" slim)
                b-field
                  b-input(name="form_text_58" placeholder="Количество сотрудников в компании" v-model="formData.number")
                p.has-text-danger {{v.errors[0]}}
              // Система налогообложения
              validation-provider(v-slot="v" rules="required" name="Система налогообложения" slim)
                b-field
                  b-select(v-model="formData.tax" expanded name="form_text_59" placeholder="Система налогообложения")
                    option(v-for="option in tax" :selected="option.selected" :key="option.id" :value="option.name") {{option.name}}
                p.has-text-danger {{v.errors[0]}}     
              
            .column.is-6
              // Сообщение
              validation-provider(v-slot="v" rules="required|max:1000" slim name="Сообщение")
                b-field
                  b-input(name="form_textarea_52" placeholder="сообщение или вопрос..." type="textarea"
                    v-model="formData.message")
                p.has-text-danger {{v.errors[0]}}

          p.help.
            Отправляя форму вы соглашаетесь на обработку ваших персональных данных в соответствие 
            с <a href="#" target="_blank" class="has-text-danger">пользовательским соглашением сайта</a>. 
            Передаваемая контактная информация используется исключительно для связи с Вами.
  
          .controls.mt-5.has-text-centered
            b-button.is-primary.is-inverted(type="is-primary"
              native-type="submit"
              @click.prevent="submit"
              :disabled="invalid"
              :class="{'is-loading': isLock}") отправить
</template>
<script>
import FormCommonMixin from '@/platform/mixins/form-common-mixin'

export default {
  mixins: [FormCommonMixin],
  data() {
    return {
      formId: 6,
      tax: [
        { id: 1, name: 'ОСН', active: true },
        { id: 2, name: 'УСН', active: true },
        { id: 3, name: 'Патент', active: true },
        { id: 4, name: 'ЕНВД', active: true },
        { id: 5, name: 'ЕСХН', active: true },

      ],
    }
  }
}
</script>

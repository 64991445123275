/**
 * помощник для регистрации компонентов
 *
 * - /components/my-component/
 *  - MyComponent.vue
 *  - index.js
 *
 * Пример индексного файла
 *
 * ```js
 * import MyComponent from '@/components/my-component'
 * import {use, registerComponent} from '@/utils/plugins'
 *
 * const Plugin = {
 *   install(Vue) {
 *     registerComponent(Vue, MyComponent)
 *   }
 * }
 *
 * use(Plugin)
 *
 * export default Plugin
 *
 * export {
 *   MyComponent
 * }
 *
 * ```
 */
export const use = (plugin) => {
  if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(plugin)
  }
}

export const registerComponent = (Vue, component) => {
  Vue.component(component.name, component)
}

export const registerComponentProgrammatic = (Vue, property, component) => {
  if (!Vue.prototype.$platform) Vue.prototype.$platform = {}
  Vue.prototype.$platform[property] = component
}

import {initPhotoSwipeFromSwiper as initGallery} from '../pswp'

/**
 * Для каждой инициализации всегда создается
 * отдельный файл
 *
 * @param selector {string} HTML селектор
 */
function init(selector) {
  initGallery(selector, {})
}

export default {
  init
}

import CallbackForm from '@/platform/components/callback/CallbackForm'
import CallbackFormTrigger from "@/platform/components/callback/CallbackFormTrigger";
import { use, registerComponent } from '@/utils/plugins'

/**
 * глобальная регистрация компонента
 * после этого компонент можно использовать
 * везде в приложении
 */
const Plugin = {
  install(Vue) {
    registerComponent(Vue, CallbackForm)
  }
}

use(Plugin)

export default Plugin

/**
 * экспорт компонента
 */
export {
  CallbackForm,
  CallbackFormTrigger
}

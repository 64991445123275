import Vue from 'vue'
import {
  ConfigProgrammatic,
  Button,
  Field,
  Icon,
  Input,
  Numberinput,
  Radio,
  Select,
  Slider,
  Tabs,
  Modal,
  Toast,
  Notification,
  Upload,
  Message,
  Rate
} from 'buefy'
import 'buefy/dist/buefy.css'

function init() {
  Vue.use(Rate)
  Vue.use(Numberinput)
  Vue.use(Icon)
  Vue.use(Tabs)
  Vue.use(Select)
  Vue.use(Input)
  Vue.use(Field)
  Vue.use(Slider)
  Vue.use(Radio)
  Vue.use(Toast)
  Vue.use(Modal)
  Vue.use(Notification)
  Vue.use(Button)
  Vue.use(Message)
  Vue.use(Upload)

  ConfigProgrammatic.setOptions({
    defaultIconPack: 'fas'
  })
}

export default {
  init
}
